import React, { useState, useRef } from "react";
import Loading from "../../components/widgets/Loading";
import { useAuth } from "../../context/Provider";
import axios from "axios";
import Modal from "../../components/widgets/Modal";

const AddCupon = () => {
  const { state } = useAuth();
  const [loading, setLoading] = useState(false);
  const [cantidad, setCantidad] = useState("");
  const [error, setError] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [mensajefromserver, setMensajeFromServer] = useState("");
  const cantidadInputRef = useRef(null);
  const dirEndPoint = process.env.REACT_APP_BACKEND;
  const token = process.env.REACT_APP_TOKEN;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que sea un número
    if (isNaN(cantidad) || cantidad <= 0) {
      setError("Debe ser un número válido");
      setCantidad("");
      cantidadInputRef.current.focus();
      return;
    }

    if (cantidad > 110) {
      setError("Debe ser un número menor que 100");
      setCantidad("");
      cantidadInputRef.current.focus();
      return;
    }

    cantidadInputRef.current.focus();
    // Limpiar errores anteriores
    setError("");
    setMensaje("");

    try {
      setLoading(true);

      const companyId = state.companyId;
      const response = await axios.post(
        `${dirEndPoint}coupons/generate?access-token=${token}`,
        {
          company_id: companyId,
          quantity: cantidad,
        }
      );

      setLoading(false);
      setMensajeFromServer(response.data.message);
      openModal();
      //}
    } catch (err) {
      // Verifica si el error es de respuesta
      if (err.response) {
        // El servidor respondió con un código de estado fuera del rango de 2xx
        setError(
          `Error: ${err.response.data.message} (Status: ${err.response.status})`
        );
      } else if (err.request) {
        // La solicitud fue realizada pero no hubo respuesta
        setError("Error: No se recibió respuesta del servidor.");
      } else {
        // Otro tipo de error
        setError(`Error: ${err.message}`);
      }
    } finally {
      setLoading(false);
      setCantidad("");
    }
  };

  return (
    <div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h3>Información</h3>
        <p>{mensajefromserver}</p>
        <button onClick={closeModal}>Entendido</button>
      </Modal>

      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">¿Cuantos cupones desea generar?</h3>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                {loading && <p>Cargando...</p>} {/* Loader */}
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-3">
                      <div className="input-group mb-3">
                        <input
                          ref={cantidadInputRef}
                          type="text"
                          placeholder="Cantidad de Cupones"
                          value={cantidad}
                          onChange={(e) => setCantidad(e.target.value)}
                          className="form-control"
                        />
                      </div>
                      {error && <p style={{ color: "red" }}>{error}</p>}
                      {mensaje && <p style={{ color: "green" }}>{mensaje}</p>}
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Generar Cupones
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {/* /.card-body */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddCupon;
